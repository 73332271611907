import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

//Prime React Component Inbuilt
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Badge } from 'primereact/badge';

// Column
import { SMSCampaignRecipients } from '../../../appconfig/DatatableSetting';

// Data table
import {
    defaultPageRowOptions,
    defaultRowOptions,
    paginatorLinks,
    showingEntries,
    SkeletonbodyTemplate,
    Skeletonitems,
} from '../../../appconfig/Settings';

//Services
import PageService from '../../../service/PageService';
import { Link } from 'react-router-dom';

export const SMSCampaignDetails = () => {
    document.title = "SMS Campaign Details | Venture Studio"

    //BreadCrumb
    const items = [
        {
            label: 'InputText',
            template: () => <Link className="p-breadcrumb-item" to="/sms-campaigns">SMS Campaigns</Link>
        },
        {
            label: 'InputText',
            template: () => <span className="p-breadcrumb-item active">Campaign Details</span>
        }
    ];
    const home = { icon: 'pi pi-home', url: '/dashboard' }

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();
    
    //Set Toast/ Filter Properties
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    
    //Loading/Page Loading
    const [pageLoad, setPageLoad] = useState(false);
    const [campaignDetails, setCampaignDetails] = useState<any>([]);
    
    // Page service
    const pageService = new PageService();
    const hasFetchedData = useRef(false);

    // use effect method
    useEffect(() => {
        if (hasFetchedData.current) return;
        hasFetchedData.current = true;

        if (location.state) {
            const state = location.state;
            getSMSCampaignsDetailsFromAPI(state);
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    // Get SMS Camapaigns
    const getSMSCampaignsDetailsFromAPI = async (state: any) => {
        // Api call
        pageService
            .getSMSCampaignDetails(state.campaign_id)
            .then((response) => {
                // Get response
                if (response) {
                    const DataList = response;
                    if (DataList.length == 0) {
                        setCampaignDetails([]);
                    } else {
                        setCampaignDetails(DataList);
                    }
                    setPageLoad(true);
                } else {
                    setPageLoad(false);
                    setCampaignDetails([]);
                }
            });
    };

    // Template for status
    const statusTemplate = (rowData: any, rowIndex: any) => {
        return(
            <>
                {rowData?.status == "delivered" ? <Badge value="Delivered" severity="success"></Badge> : <Badge value="Pending" severity="warning"></Badge> }
            </>
        );
    };

    // Template for full name
    const fullNameTemplate = (rowData: any, rowIndex: any) => {
        return (
            <>
                {rowData?.recipient_details?.first_name !== undefined ? rowData?.recipient_details?.first_name + " " + rowData?.recipient_details?.last_name : JSON.parse(rowData?.extra_people_detail)["first_name"] + " " + JSON.parse(rowData?.extra_people_detail)["last_name"]}
            </>
        );
    };

    return(
        <>
            <div className="page-header">
                <div className="page-leftheader">
                    <div className="page-header-info">
                        <div className="page-title"><Button icon="pi pi-arrow-left" className="link-prev-btn" onClick={() => navigate(-1)} /> Campaign Details {!window.cn(campaignDetails) && campaignDetails?.status == "Pending" ? <><Badge value="Pending" severity="warning"></Badge></> : campaignDetails?.status == "Completed" ? <><Badge value="Completed" severity="success"></Badge></> : <><Badge value="Running" severity="info"></Badge></>}</div>
                        <div className="main-content-breadcrumb">
                            <BreadCrumb model={items} home={home} />
                        </div>
                    </div>
                </div>
                <div className="page-rightheader">
                    <div className="btn-icon-list">
                        <div className="page-header-search"></div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="field col">
                        <div className="grid">
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Name</div>
                                    <div className="viewcard-text">{!window.cn(campaignDetails) ? campaignDetails?.campaign_name : ""}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Start Datetime</div>
                                    <div className="viewcard-text">{!window.cn(campaignDetails) && campaignDetails?.start_datetime !== null ? campaignDetails?.start_datetime : "Not Added Yet"}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Total Recipients</div>
                                    <div className="viewcard-text">{!window.cn(campaignDetails) ? campaignDetails?.recipients.length : ""}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Total Delivered</div>
                                    <div className="viewcard-text">{!window.cn(campaignDetails) ? campaignDetails?.delivered_count : ""}</div>
                                </div>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <div className="viewcard-box">
                                    <div className="viewcard-title">Total Pending</div>
                                    <div className="viewcard-text">{!window.cn(campaignDetails) ? campaignDetails?.pending_count : ""}</div>
                                </div>
                            </div>
                            <div className="field col-6 flex flex-column">
                                <p><b>Description: </b>{campaignDetails?.campaign_description}</p>
                            </div>
                            <div className="field col-6 flex flex-column">
                                <p><b>Message Flow: </b>{campaignDetails?.message_flow}</p>
                            </div>
                            <div className="field col-6 flex flex-column">
                                <p><b>Message 1: </b>{campaignDetails?.message_body_1}</p>
                            </div>
                            <div className="field col-6 flex flex-column">
                                <p><b>Message 2: </b>{campaignDetails?.message_body_2}</p>
                            </div>
                            <div className="field col-6 flex flex-column">
                                <p><b>Goups: </b>{campaignDetails?.selected_group}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <div className="card-title-box">
                        <h3 className="card-title">Recipients</h3>
                    </div>
                </div>
                <div className="card-body">
                    {pageLoad ? (
                        <>
                            <DataTable
                                className="datatable-responsive"
                                value={campaignDetails?.recipients}
                                paginator={campaignDetails?.recipients.length > 0 && true}
                                rows={defaultRowOptions}
                                rowsPerPageOptions={defaultPageRowOptions}
                                paginatorTemplate={paginatorLinks}
                                currentPageReportTemplate={showingEntries}
                                emptyMessage={"No Recipients Found"}
                            >
                                {SMSCampaignRecipients.map((col, i) => {
                                    if (col.field === 'status') {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={statusTemplate}
                                            />
                                        );
                                    } else if (col.field === 'full_name') {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={fullNameTemplate}
                                            />
                                        );
                                    } else if (col.field === 'sr_no') {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={(_, { rowIndex }) => rowIndex + 1}
                                            />
                                        );
                                    } else {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                            />
                                        );
                                    }
                                })}
                            </DataTable>
                        </>
                    ) : (
                        <>
                            {/* Skeleton Data table */}
                            <DataTable value={Skeletonitems}
                                className="datatable-responsive" stripedRows
                            >
                                {SMSCampaignRecipients.map((col, i) => (
                                    <Column
                                        key={col.field}
                                        field={col.field}
                                        header={col.header}
                                        body={SkeletonbodyTemplate}
                                    />
                                ))}
                            </DataTable>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
