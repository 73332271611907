import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import AppMenu from './AppMenu';
import { Avatar } from 'primereact/avatar';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';

//Services
import PageService from '../service/PageService';
import { formatDistanceToNow } from 'date-fns';
import { addTaskValidate } from '../config/Validate';


const AppTopbar = (props: any) => {
    // Page Service
    const pageService = new PageService();

    const toast = useRef<any>(null);

    const [dashboardData, setDashboardData] = useState<any>({});
    const [notifications, setNotifications] = useState<any>([]);
    const [visibleNotification, setVisibleNotification] = useState<boolean>(false);
    const [taskModal, setTaskModal] = useState<boolean>(false);
    const [taskDetail, setTaskDetail] = useState<any>("");
    const [taskLoader, setTaskLoader] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});

    // use effect method
    useEffect(() => {
        setProfileImg(localStorage.getItem("profile_img"));
    }, []);

    useEffect(() => {
        getDashboardDataFromApi();
    }, []);
    const [profileImg, setProfileImg] = useState<any>("");

    const menuRef = useRef(null);
    // Get Dashboard Data
    const getDashboardDataFromApi = () => {

        // Api call
        pageService
            .getDashboardData()
            .then((response) => {
                // Get response
                if (response) {
                    setDashboardData({
                        "notification_count": response?.notification_count
                    });
                }
            });
    };
    // Get notifications from api
    const getNotificationsApiCall = () => {
        pageService
            .getNotifications()
            .then((response) => {
                // Get response
                if (response) {
                    setNotifications(response);
                } else {
                    setNotifications([]);
                }
            });
    };

    // Notifications show
    const handleNotificationShowClick = () => {
        setVisibleNotification(true);
        getNotificationsApiCall();
    };

    // Notifications hide
    const handleNotificationHideClick = () => {
        setVisibleNotification(false);
        getDashboardDataFromApi();
    }

    // On clear notification
    const clearNotification = (id: any) => {
        // Clear notification api call
        pageService
            .clearNotification(id)
            .then((response) => {
                // Get response
                if (response) {
                    getNotificationsApiCall();
                }
            });
    };
    // Format created_at for Posted time show
    const PostedDate = (datetime: any) => {
        const postedTime = formatDistanceToNow(new Date(datetime), { addSuffix: true });
        return postedTime;
    };
    const onTopbarSubItemClick = (event: any) => {
        event.preventDefault();
    };

    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        localStorage.removeItem("name");
        localStorage.removeItem("profile_img");
        window.location.href = "/";
    };

    // Open add task modal
    const openTaskModal = () => {
        setTaskModal(true);
    };

    // Close add task modal
    const closeTaskModal = () => {
        setTaskDetail("");
        setTaskModal(false);
        setTaskLoader(false);
        setErrors({});
    };

    // Add task api call
    const addTaskApiCall = () => {
        const { errors, isError } = addTaskValidate(taskDetail);
        setErrors(errors);

        try {
            if (!isError) {
                setTaskLoader(true);

                // request data
                let formData: any = new FormData();
                formData.append('id', localStorage.getItem("id"));
                formData.append('task_detail', taskDetail);

                // call api
                pageService.addTask(formData).then((response) => {
                    // Get response
                    if (response) {
                        setTaskLoader(false);
                        setTaskModal(false);
                        setTaskDetail("");
                        toast.current?.show({
                            severity: 'success',
                            summary: 'Success',
                            detail: response.message,
                        });
                        setTimeout(() => {
                            navigate(0);
                        }, 800);
                    } else {
                        setTaskLoader(false);
                        setTaskModal(true);
                        toast.current?.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Something went wrong, Please try again.',
                        });
                    }
                });
            }
        } catch (error: any) {
            setTaskLoader(false);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response.data.error,
            });
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>

                    <button className="logo p-link" onClick={() => navigate('/dashboard')}>
                        <img src="/assets/images/logo.png" className="logo-img" />
                    </button>
                </div>

                {/* <AppMenu
                    model={props.items}
                    menuMode={props.menuMode}
                    colorScheme={props.colorScheme}
                    menuActive={props.menuActive}
                    activeInlineProfile={props.activeInlineProfile}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    toggleMenu={props.onToggleMenu}
                    onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                    onMenuClick={props.onMenuClick}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMenuItemClick={props.onMenuItemClick}
                /> */}

                <div className="layout-topbar-right">
                    <ul className="layout-topbar-right-items">
                        <li id="profile" className={classNames('profile-item', { 'active-topmenuitem': props.topbarMenuActive })}>
                            <button className="user-top-link p-link mr-0" onClick={props.onTopbarItemClick} >
                                {
                                    profileImg == "" || profileImg == null || profileImg == "null" ?
                                        <img className="account-userimg" src="/assets/images/avatar.jpg" alt="{name}" />
                                        :
                                        <img className="account-userimg" src={profileImg} alt="{name}" />
                                }
                            </button>
                            {/* {
                                !window.cn(localStorage.getItem("full_name")) &&
                                <span className="account-username" onClick={props.onTopbarItemClick} >{localStorage.getItem("full_name")}</span>
                            } */}
                            <ul className="dropdown-menu fadeInDown">
                                <li className="p-0" role="menuitem">
                                    <div className="drop-userinfo">
                                        <div className="drop-userinfo-img">
                                            {
                                                profileImg == "" || profileImg == null || profileImg == "null" ?
                                                    <img className="drop-userinfo-userimg" src="/assets/images/avatar.jpg" alt="{name}" />
                                                    :
                                                    <img className="drop-userinfo-userimg" src={profileImg} alt="{name}" />
                                            }
                                        </div>
                                        <div className="drop-userinfo-details">
                                            {
                                                (!window.cn(localStorage.getItem("full_name")) && !window.cn(localStorage.getItem("user_type"))) &&
                                                <><span className="drop-userinfo-name" onClick={props.onTopbarItemClick} >{localStorage.getItem("full_name")}</span>
                                                    <span className="drop-userinfo-text" onClick={props.onTopbarItemClick} >{localStorage.getItem("user_type")}</span></>
                                            }
                                        </div>
                                    </div>
                                </li>
                                {
                                    localStorage.getItem("user_type") === "admin" ?
                                        <li role="menuitem">
                                            <button className="p-link" onClick={() => navigate('/role-management')}><span>Role Management</span></button>
                                        </li>
                                    :
                                        <></>
                                }
                                <li role="menuitem">
                                    <button className="p-link" onClick={() => navigate('/user-settings')}><span>Account Settings</span></button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link" onClick={() => navigate('/user-settings')}><span>My Subscription</span></button>
                                </li>
                                {/* <li role="menuitem">
                                    <button className="p-link" onClick={() => navigate('/change-password')}><span>Change Password</span></button>
                                </li> */}
                                <li role="menuitem">
                                    <button className="p-link" onClick={logout}><span>Sign Out</span></button>
                                </li>
                            </ul>
                        </li>
                        <li>
                            {
                                localStorage.getItem("user_type") == "broker" || localStorage.getItem("user_type") == "admin" ?
                                    <>{
                                        localStorage.getItem("user_type") == "broker" ?
                                            <button className="btn-topbar" onClick={() => openTaskModal()} style={{ cursor: 'pointer' }}>
                                                <i className="pi pi-check-circle" style={{ fontSize: '1.5rem' }}>
                                                </i>
                                            </button>
                                            :
                                            <></>
                                    }
                                    <button className="btn-topbar" onClick={() => handleNotificationShowClick()}>
                                        <i className="pi pi-bell p-overlay-badge"></i>
                                        <Badge className="badge-notification-count" value={!window.cn(dashboardData) && dashboardData?.notification_count != undefined ? dashboardData?.notification_count : 0}></Badge>
                                    </button></>
                                    :
                                    <></>
                            }
                        </li>
                    </ul>
                </div>
            </div>
            {/* Notification Sidebar */}
            <Sidebar className='p-slider-header-custom' style={{ width: "500px" }} visible={visibleNotification} onHide={() => handleNotificationHideClick()} position='right'>
                <div className="p-slider-header">Notifications</div>
                {
                    !window.cn(notifications) && notifications.length > 0 ?
                        <ul className="list-none m-0 p-0">
                            {
                                notifications.map((item: any, index: any) => {
                                    return (
                                        <>
                                            <li>
                                                <a className="sidebar-notification-box">
                                                    <Avatar style={item?.user?.user_type == "broker" ? { backgroundColor: '#2196F3' } : { backgroundColor: '#9c27b0' }} label={item?.user?.full_name.charAt(0).toUpperCase()} size="xlarge" shape="circle" />
                                                    <div className="sidebar-notification-content">
                                                        <div className="sidebar-notification-user" style={{ color: "black" }}>{item?.user?.full_name}</div>
                                                        <div className="sidebar-notification-text">{item?.notification_message}</div>
                                                        <div className="sidebar-notification-date"><i className="ti ti-clock mr-2"></i>{PostedDate(item?.created_at)}</div>
                                                    </div>
                                                    <Button
                                                        className="ml-auto cursor-pointer p-button p-component p-button-icon-only p-button-text p-button-rounded p-button-secondary"
                                                        icon="pi pi-times"
                                                        aria-label="Cancel"
                                                        onClick={() => clearNotification(item?.id)}
                                                    />
                                                </a>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                        :
                        <></>
                }
            </Sidebar>

            {/* Add Update Dialog */}
            <Dialog
                visible={taskModal}
                style={{ width: '450px' }}
                className="p-fluid"
                header="Add Today's Task"
                modal
                footer={
                    <>
                        <Button
                            label="Cancel"
                            icon="pi pi-times"
                            className="p-button-secondary"
                            onClick={closeTaskModal}
                        />
                        <Button
                            label="Submit"
                            icon="pi pi-check"
                            className="p-button-success"
                            onClick={() => addTaskApiCall()}
                            loading={taskLoader}
                        />
                    </>
                }
                onHide={closeTaskModal}
            >
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="name">Enter Task <span style={{ color: "red" }}>*</span></label>
                        <InputTextarea
                            value={taskDetail}
                            onChange={(e) => setTaskDetail(e.target.value)}
                            rows={5}
                            cols={30}
                        />
                        <small className="p-invalid-txt">{errors['task_detail']}</small>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default AppTopbar;
