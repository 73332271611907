import { Skeleton } from 'primereact/skeleton';

export const APP_BASE_URL =
  'https://api.infusionventurestudio.com';

// export const APP_BASE_URL =
//   'http://127.0.0.1:8000';

export const ASSET_PATH = APP_BASE_URL;

// PAGINATION
//default pagination Row data
export const defaultRowOptions = 25;
export const defaultRowOptionsDashboard = 6;

//default PerPageRow options
export const defaultPageRowOptions = [10, 25, 50, 100];

//paginatior entries showing
export const showingEntries =
  'Showing {first} to {last} of {totalRecords}';

//paginator links & dropdown
export const paginatorLinks =
  'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown';

// PAGINATION

//date picker options
export const datePickerOptions = [
  { label: 'Custom', value: 'custom' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 days', value: 'last7day' },
  { label: 'Last 30 days', value: 'last30days' },
  { label: 'Last 90 days', value: 'last90days' },
  { label: 'Last month', value: 'lastmonth' },
  { label: 'Last year', value: 'lastyear' },
  { label: 'Week to date', value: 'weektodate' },
  { label: 'Month to date', value: 'monthtodate' },
  { label: 'Year to date', value: 'yeartodate' },
];

//emilPattern
export const emailPattern = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);

//Date Convert To YYYY/MM/DD
export function convertDateFrom(date: any) {
  date = new Date(date); //Date pass
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return (
    year +
    '-' +
    (month <= 9 ? '0' + month : month) +
    '-' +
    (day <= 9 ? '0' + day : day)
  );
}

//Skeleton

// skeleton items & length
export const Skeletonitems: number[] = Array.from({ length: 10 }, (v, i) => i);

// SKELETON FOR COLUMN BODY
export function SkeletonbodyTemplate(): JSX.Element {
  return <Skeleton></Skeleton>;
};

export const userStatus = [
  { name: 'All', code: "All" },
  // { name: 'Pending', code: 0 },
  { name: 'Active', code: 1 },
  { name: 'Access Revoked', code: 2 },
];

export const careerStatus = [
  { name: 'All', code: "All" },
  { name: 'Published', code: "Published" },
  { name: 'Inactive', code: "Inactive" },
  { name: 'Removed', code: "Removed" },
];

export const jobRolesStatus = [
  { name: 'All', code: "All" },
  { name: 'Active', code: 1 },
  { name: 'Inactive', code: 0 },
];

export const workTypeDropdown = [
  { name: 'Freelance', code: "Freelance" },
  { name: 'FullTime', code: "FullTime" },
  { name: 'Internship', code: "Internship" },
];

export const applicantStatus = [
  { name: 'All', code: "All" },
  { name: 'Under Review', code: "Under Review" },
  { name: 'Top Applicants', code: "Top Applicant" },
  { name: 'Currently Considering', code: "Currently Considering" },
  { name: 'Consider In Future', code: "Consider In Future" },
  { name: 'Not Eligible', code: "Not Eligible" },
  { name: 'Selected', code: "Selected" },
];

export const applicantStatusChange = [
  { name: 'Under Review', code: "Under Review" },
  { name: 'Top Applicant', code: "Top Applicant" },
  { name: 'Currently Considering', code: "Currently Considering" },
  { name: 'Consider In Future', code: "Consider In Future" },
  { name: 'Not Eligible', code: "Not Eligible" },
  { name: 'Selected', code: "Selected"},
];

export const userTypeSelectionDropdown = [
  { name: 'Client', code: "client" },
  { name: 'Investor', code: "investor" },
  { name: 'Broker', code: "broker" },
];

export const investmentMaterialApprovalStatus = [
  { name: 'Pending', code: 0 },
  { name: 'Approved', code: 1 },
  { name: 'Rejected', code: 2 },
];

export const ceEngagementStatusDropDown = [
  { name: 'Macro', code: "Macro" },
  { name: 'Micro', code: "Micro" },
  { name: 'Engaged', code: "Engaged" },
  { name: 'Assigned', code: "Assigned" },
  { name: 'Converted', code: "Converted" },
];

export const currencyDropDown = [
  { name: 'INR', code: "INR" },
  { name: 'USD', code: "USD" },
];

export const taskStatusFilter = [
  { name: 'Completed', code: "Completed" },
  { name: 'Pending', code: "Pending" },
];

export const kanbanColumns = [
  {
    id: "Introduction",
    text: "Introduction"
  },
  {
    id: "Appointment",
    text: "Appointment"
  },
  {
    id: "DD Call",
    text: "DD Call"
  },
  {
    id: "Ask to Invest",
    text: "Ask to Invest"
  },
  {
    id: "New Investor",
    text: "New Investor"
  },
  {
    id: "Not Interested",
    text: "Not Interested"
  },
  {
    id: "Follow Up AA 1",
    text: "Follow Up AA 1"
  },
  {
    id: "DNC",
    text: "DNC"
  },
  {
    id: "Follow Up AA 2",
    text: "Follow Up AA 2"
  },
  {
    id: "Review Lead",
    text: "Review Lead"
  },
  {
    id: "Follow Up",
    text: "Follow Up"
  },
  {
    id: "UnAccounted",
    text: "UnAccounted"
  }
];

export const smsCampaignGroup = [
  { name: 'Potential Investors', code: "Potential Investors" },
  { name: 'Current Investors', code: "Current Investors" },
  { name: 'Subscribers', code: "Subscribers" },
];

export const smsCampaignSubscriberSubGroup = [
  { name: 'Cold Breath Fitness', code: 'Cold Breath Fitness' },
];